:root {
  --first-column-width: 105px;
}

.blog-meta-tokens {
  background: var(--color-bg);
  padding-left: 0px;
  display: grid;
  grid-template-columns: var(--first-column-width) 320px repeat(auto-fit, minmax(120px, 1fr));
  justify-items: center;
  align-items: center;

  position: relative;

  li {
    color: var(--color-text);
    padding-left: 16px;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 16px;
    width: 100%;
    background-color: var(--color-bg);
    position: relative;

    &:not(:last-child)::after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 1px;
      background-color: #ccc;
    }
    &:last-child {
      padding-right: 0px;
    }
    &:first-child {
      padding-left: 0px;
    }
    display: flex;
    justify-content: start;
    align-items: center;
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    grid-template-columns: 1fr;

    li {
      margin: 0;
      padding: 0;
      justify-content: start;
      &:not(:last-child)::after {
        content: none;
      }
    }
  }
}

.blog-meta-tokens.alternative-layout {
  --first-column-width: 80px;
  grid-template-columns: var(--first-column-width) repeat(auto-fit, minmax(120px, 1fr));
  width: 80%;
}
