/*-------------------------
    Spacing
-------------------------*/
.edu-section-gap {
  background: var(--color-dashboard-bg);
  padding: 0px 0 90px;
  @media #{$md-layout} {
    padding: 95px 0 100px;
  }
  @media #{$sm-layout} {
    padding: 20px 0 20px;
  }
}

.gap-tb-text {
  padding: 115px 0 112px;
  @media #{$md-layout} {
    padding: 95px 0 92px;
  }
  @media #{$sm-layout} {
    padding: 75px 0 72px;
  }
}

.section-gap-equal {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-dashboard-bg);
  padding-top: 32px;
  padding-bottom: 32px;
  min-height: 60vh;
  @media #{$md-layout} {
    padding: 32px 0;
  }
  @media #{$sm-layout} {
    padding: 32px 0;
  }
}

.section-gap-equal-no-data {
  background: var(--color-dashboard-bg);
  padding: 120px 0;
  @media #{$md-layout} {
    padding: 100px 0;
  }
  @media #{$sm-layout} {
    padding: 80px 0;
  }
}

.gap-top-text {
  padding-top: 115px;
  @media #{$md-layout} {
    padding-top: 95px;
  }
  @media #{$sm-layout} {
    padding-top: 75px;
  }
}

.gap-bottom-text {
  padding-bottom: 112px;
  @media #{$md-layout} {
    padding-bottom: 92px;
  }
  @media #{$sm-layout} {
    padding-bottom: 72px;
  }
}

.gap-top-equal {
  padding-top: 120px;
  @media #{$md-layout} {
    padding-top: 100px;
  }
  @media #{$sm-layout} {
    padding-top: 80px;
  }
}

.gap-bottom-equal {
  padding-bottom: 120px;
  @media #{$md-layout} {
    padding-bottom: 100px;
  }
  @media #{$sm-layout} {
    padding-bottom: 80px;
  }
}

//Large Section Specing

.section-gap-large {
  padding: 145px 0 150px;
  @media #{$lg-layout} {
    padding: 115px 0 120px;
  }
  @media #{$md-layout} {
    padding: 95px 0 100px;
  }
  @media #{$sm-layout} {
    padding: 75px 0 80px;
  }
}

.gap-large-text {
  padding: 145px 0 142px;
  @media #{$lg-layout} {
    padding: 115px 0 112px;
  }
  @media #{$md-layout} {
    padding: 95px 0 92px;
  }
  @media #{$sm-layout} {
    padding: 75px 0 72px;
  }
}

.gap-large-equal {
  padding: 150px 0;
  @media #{$lg-layout} {
    padding: 120px 0;
  }
  @media #{$md-layout} {
    padding: 100px 0;
  }
  @media #{$sm-layout} {
    padding: 80px 0;
  }
}

.gap-lg-top-text {
  padding-top: 145px;
  @media #{$lg-layout} {
    padding-top: 115px;
  }
  @media #{$md-layout} {
    padding-top: 95px;
  }
  @media #{$sm-layout} {
    padding-top: 75px;
  }
}
.gap-lg-bottom-text {
  padding-bottom: 142px;
  @media #{$lg-layout} {
    padding-bottom: 112px;
  }
  @media #{$md-layout} {
    padding-bottom: 92px;
  }
  @media #{$sm-layout} {
    padding-bottom: 72px;
  }
}

.gap-lg-bottom-equal {
  padding-bottom: 150px;
  @media #{$lg-layout} {
    padding-bottom: 120px;
  }
  @media #{$md-layout} {
    padding-bottom: 100px;
  }
  @media #{$sm-layout} {
    padding-bottom: 80px;
  }
}

.gap-lg-top-equal {
  padding-top: 150px;
  @media #{$lg-layout} {
    padding-top: 120px;
  }
  @media #{$md-layout} {
    padding-top: 100px;
  }
  @media #{$sm-layout} {
    padding-top: 80px;
  }
}

.brand-section-gap {
  padding: 85px 0;
}

// Margin
.mb--10 {
  margin-bottom: 10px;
}

.mb--18 {
  margin-bottom: 18px;
}

.mb--20 {
  margin-bottom: 20px;
}

.mb--30 {
  margin-bottom: 30px;
}

.mb--32 {
  margin-bottom: 32px;
}

.mb--40 {
  margin-bottom: 40px;
}

.mb--50 {
  margin-bottom: 50px !important;
}

.mb--60 {
  margin-bottom: 60px !important;
}

.mb--90 {
  margin-bottom: 90px !important;
}
.mt--50 {
  margin-top: 50px;
}

.mt--8 {
  margin-top: 8px;
}

.mt--16 {
  margin-top: 16px;
}

.mt--0 {
  margin-top: 0px !important;
}

.mt--20 {
  margin-top: 20px !important;
}

.ml--8 {
  margin-left: 8px !important;
}

.ml--16 {
  margin-left: 16px !important;
}

.ml--4 {
  margin-left: 4px !important;
}

@media only screen and (min-width: 1200px) {
  .col-pl--35 {
    padding-left: 35px;
  }
  .col-pr--35 {
    padding-right: 35px;
  }
}

// Padding
.pt--50 {
  padding-top: 50px;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-32 {
  padding-top: 32px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-16 {
  padding-bottom: 16px !important;
}

.pb-32 {
  padding-bottom: 32px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-6 {
  margin-right: 6px !important;
}

.mb--65 {
  margin-bottom: -65px !important;
}

.ml-6 {
  margin-left: 6px !important;
}
.ml-2 {
  margin-left: 2px !important;
}
