/*-------------------
  About Styles
---------------------*/
.about-image-gallery {
  position: relative;
  padding: 90px 0 60px;
  @media #{$md-layout} {
    text-align: center;
  }
  img {
    border-radius: 10px;
  }
  .video-box {
    position: absolute;
    top: 0;
    right: 40px;
    @media #{$large-mobile} {
      transform: scale(0.7);
      right: 0;
    }
    .inner {
      padding: 20px;
      background-color: var(--color-white);
      border-radius: 10px;
      box-shadow: 0px 40px 70px rgba(27, 20, 78, 0.1);
      .thumb {
        position: relative;
        text-align: center;
        img {
          border-radius: 6px;
        }
        .popup-icon {
          cursor: pointer;
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
          transform: translateY(-50%);
          margin: 0 auto;
          height: 60px;
          width: 60px;
          line-height: 62px;
          background-color: var(--color-white);
          border-radius: 50%;
          color: var(--color-secondary);
          font-size: 18px;
          i {
            margin-left: 5px;
          }
          &:hover {
            background-color: var(--color-primary);
            color: var(--color-white);
          }
        }
      }
      .loading-bar {
        margin: -20px 0;
        padding-top: 20px;
        span {
          display: block;
          height: 7px;
          background-color: #eaf0f2;
          border-radius: 10px;
          margin: 20px 0;
          &:first-child {
            width: 80%;
          }
          &:nth-child(2n) {
            width: 45%;
          }
        }
      }
    }
  }
  .award-status {
    position: absolute;
    right: 70px;
    bottom: 0;
    @media #{$small-mobile} {
      right: 0;
    }
    .inner {
      display: flex;
      padding: 22px 20px 18px 30px;
      align-items: center;
      box-shadow: 0px 40px 70px rgba(27, 20, 78, 0.1);
      background: #ffffff;
      border-radius: 10px;
      .icon {
        width: 60px;
        height: 60px;
        background: rgba(26, 182, 157, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin-right: 15px;
        color: var(--color-primary);
        font-size: 30px;
      }
      .content {
        text-align: left;
        .title {
          color: var(--color-primary);
          font-size: 24px;
          line-height: 1;
          margin-bottom: 0;
        }
      }
    }
  }
  .shape-group {
    li {
      @extend %liststyle;
      position: absolute;
      z-index: -1;
      &.shape-1 {
        top: 20px;
        left: -100px;
        @media only screen and (max-width: 991px) {
          left: 0;
        }
      }
      &.shape-2 {
        bottom: -30px;
        right: 130px;
      }
    }
  }
}
.about-content {
  padding-left: 30px;
  @media only screen and (max-width: 991px) {
    padding-left: 0;
  }
  .section-title {
    margin-bottom: 20px;
  }
  .features-list {
    @extend %liststyle;
    li {
      font-weight: var(--p-medium);
      font-family: var(--font-secondary);
      color: var(--color-heading);
      padding-left: 35px;
      position: relative;
      margin-bottom: 16px;
      margin-top: 16px;
      &:before {
        content: '\e913';
        font-family: 'icomoon', sans-serif;
        color: var(--color-tertiary);
        font-size: 19px;
        position: absolute;
        left: 0;
        top: -3px;
      }
    }
  }
}

.about-style-1,
.about-style-2 {
  position: relative;
  .shape-group {
    @extend %liststyle;
    li {
      position: absolute;
      top: 107px;
      right: -210px;
      z-index: -1;
      &.shape-1 {
        &.circle {
          span {
            display: block;
            height: 101px;
            width: 101px;
            border: 15px solid var(--color-tertiary);
            border-radius: 50%;
          }
        }
      }
      @media #{$lg-layout-1} {
        right: -110px;
      }
      @media #{$custom-lg-device-eight} {
        right: -50px;
      }
    }
  }
}

.about-style-1 {
  .about-image-gallery {
    .shape-group {
      li {
        @media #{$md-layout-1} {
          display: none;
        }
        &.shape-1 {
          top: 20px;
          left: -100px;
        }
        &.shape-2 {
          top: 4px;
          left: -50px;
        }
        &.shape-3 {
          bottom: -33px;
          right: 120px;
          top: inherit;
        }
      }
    }
  }
}

.about-style-2 {
  .about-image-gallery {
    position: relative;
    padding: 80px 0 60px 70px;
    @media #{$sm-layout} {
      padding: 0;
    }
    .author-box {
      position: absolute;
      top: 15px;
      right: 0;
      max-width: 220px;
      @media #{$sm-layout} {
        display: none;
      }
      .inner {
        background-color: var(--color-white);
        box-shadow: 0px 40px 70px rgba(15, 11, 44, 0.2);
        padding: 40px 20px;
        border-radius: 10px 40px 10px 80px;
        text-align: center;
        .thumb {
          margin-bottom: 20px;
        }
        .content {
          .title {
            margin-bottom: 10px;
          }
          p {
            margin-bottom: 0;
          }
        }
      }
    }
    .award-status {
      right: auto;
      left: 0;
      .inner {
        box-shadow: 0px 40px 60px rgba(15, 11, 44, 0.15);
        border-radius: 30px 10px 50px 10px;
        .icon {
          background: rgba(238, 74, 98, 0.1);
          color: var(--color-secondary);
        }
        .content {
          .title {
            color: var(--color-secondary);
          }
          .subtitle {
            color: var(--color-heading);
          }
        }
      }
    }
    .shape-group {
      li {
        &.shape-1 {
          top: 0;
          left: -55px;
          right: inherit;
        }
        &.shape-2 {
          top: 0;
          left: 0;
          bottom: inherit;
          right: inherit;
        }
        &.shape-3 {
          top: inherit;
          bottom: -15px;
          right: -5px;
        }
      }
    }
  }
  .about-content {
    padding-left: 80px;
    @media #{$smlg-device} {
      padding-left: 30px;
    }
    @media only screen and (max-width: 991px) {
      padding-left: 0;
    }
  }
}

.about-style-3 {
  padding: 90px 0 120px;
  position: relative;
  @media #{$md-layout} {
    padding: 90px 0 100px;
  }
  @media #{$sm-layout} {
    padding: 80px 0;
  }
  .about-content {
    padding-right: 30px;
    padding-left: 0;
    @media #{$large-mobile} {
      padding-right: 0;
    }
    .section-title {
      margin-bottom: 34px;
    }
    .nav-tabs {
      border-bottom: none;
      margin: -8px -16px;
      padding-bottom: 20px;
      .nav-item {
        margin: 8px 16px;
        position: relative;
        &:after {
          content: '';
          height: 14px;
          width: 2px;
          background-color: #e5e5e5;
          position: absolute;
          top: 2px;
          right: -18px;
        }
        &:last-child {
          &:after {
            display: none;
          }
        }
      }
      .nav-link {
        color: var(--color-heading);
        font-size: 17px;
        font-weight: var(--p-semi-bold);
        font-family: var(--font-secondary);
        background-color: transparent;
        border: none;
        padding: 0;
        position: relative;
        padding-bottom: 1px;
        &:after {
          content: '';
          height: 2px;
          width: 0;
          background-color: var(--color-secondary);
          position: absolute;
          bottom: 0;
          left: 0;
          opacity: 0;
          transition: var(--transition);
        }
        &:hover,
        &.active {
          color: var(--color-secondary);
          &:after {
            width: 100%;
            opacity: 1;
          }
        }
      }
    }
    .tab-content {
      p {
        margin-bottom: 20px;
      }
      .features-list {
        li {
          &:before {
            color: var(--color-secondary);
          }
        }
      }
    }
  }
  .about-image-gallery {
    padding: 80px 0 65px 30px;
    @media #{$md-layout} {
      text-align: left;
      padding: 20px 0 65px;
    }
    @media #{$sm-layout} {
      padding: 20px 0 65px;
    }
    @media #{$small-mobile} {
      padding: 0;
    }
    .main-img-2 {
      position: absolute;
      bottom: 0;
      right: 0;
      @media #{$md-layout} {
        right: 115px;
      }
      @media #{$small-mobile} {
        display: none;
      }
    }
    .shape-group {
      @extend %liststyle;
      li {
        position: absolute;
        z-index: -1;
        img {
          position: initial;
        }
        &.shape-1 {
          left: inherit;
          right: 95px;
          top: -15px;
        }
        &.shape-2 {
          left: inherit;
          bottom: inherit;
          right: 39px;
          top: 13px;
          z-index: -2;
        }
        &.shape-3 {
          top: inherit;
          right: inherit;
          left: -2px;
          bottom: 33px;
        }
        &.shape-4 {
          right: 40px;
          bottom: -25px;
          z-index: -1;
          span {
            display: block;
            height: 320px;
            width: 320px;
            border: 1px solid var(--color-border);
            border-radius: 50%;
          }
        }
      }
    }
  }
  .shape-group {
    @extend %liststyle;
    @media #{$sm-layout} {
      display: none;
    }
    li {
      position: absolute;
      z-index: -1;
      img {
        position: initial;
      }
      &.shape-5 {
        left: 110px;
        top: 20px;
        @media #{$lg-layout-1} {
          left: 0;
          top: 0;
        }
        @media #{$custom-lg-device-eight} {
          left: -90px;
        }
      }
      &.shape-6 {
        top: -350px;
        left: 80px;
        z-index: -1;
        span {
          display: block;
          height: 470px;
          width: 470px;
          border: 1px solid var(--color-border);
          border-radius: 50%;
        }
        @media #{$lg-layout-1} {
          left: 0;
        }
        @media #{$custom-lg-device-eight} {
          left: -90px;
        }
      }
    }
  }
}

.about-style-4 {
  .about-content {
    padding-right: 0;
    padding-left: 30px;
    .section-title {
      p {
        margin-top: 26px;
      }
    }
    .features-list {
      margin-bottom: 35px;
      li {
        &:before {
          color: var(--color-secondary);
        }
      }
    }
  }
  .about-image-gallery {
    padding: 80px 0 65px;
    margin-right: 30px;
    .main-img-1 {
      background-color: var(--color-white);
      padding: 20px;
      display: inline-block;
      border-radius: 20px;
      box-shadow: 0 20px 40px 0 rgba(42, 11, 88, 0.1);
    }
    .main-img-2 {
      position: absolute;
      bottom: 0;
      right: 0;
    }
    .shape-group {
      .shape-1 {
        left: inherit;
        right: 85px;
        top: 0;
      }
      .shape-2 {
        right: 31px;
        left: inherit;
        top: 32px;
        z-index: -2;
        bottom: inherit;
      }
      .shape-3 {
        right: inherit;
        left: -32px;
        bottom: 32px;
      }
      .shape-4 {
        right: 70px;
        bottom: -25px;
        z-index: -1;
        span {
          display: block;
          height: 320px;
          width: 320px;
          border: 1px solid var(--color-border);
          border-radius: 50%;
        }
      }
    }
  }
}

.about-style-5 {
  position: relative;
  .section-title {
    margin-bottom: 60px;
  }
  .about-image-gallery {
    padding: 0;
    .shape-group {
      @media #{$smlg-device} {
        display: block;
      }
      li {
        &.shape-1 {
          top: -40px;
          right: -10px;
          left: inherit;
          bottom: inherit;
          z-index: 1;
        }
        &.shape-2 {
          right: auto;
          left: -80px;
          bottom: -78px;
          animation: rotateIt 10s linear infinite;
        }
      }
    }
  }
  .about-content {
    .features-list {
      padding-bottom: 18px;
      li {
        &:before {
          color: var(--color-secondary);
        }
      }
    }
  }
  .shape-group {
    @extend %liststyle;
    li {
      position: absolute;
      z-index: -1;
      &.shape-1 {
        bottom: 80px;
        right: 180px;
      }
    }
  }
}

.about-style-6 {
  position: relative;
  padding: 90px 0 50px;
  background-color: var(--color-white);
  z-index: 2;
  @media #{$sm-layout} {
    padding: 50px 0;
  }
  @media #{$small-mobile} {
    padding: 20px 0 50px;
  }
  &:after {
    content: url(../../images/about/shape-32.png);
    position: absolute;
    top: -110px;
    left: 0;
    right: 0;
    z-index: 2;
  }
  .about-content {
    .section-title {
      margin-bottom: 30px;
      p {
        margin-top: 25px;
      }
    }
    .about-mission {
      margin-bottom: 20px;
      .title {
        margin-bottom: 14px;
      }
      p {
        margin-bottom: 25px;
      }
      .features-list {
        li {
          &:before {
            color: var(--color-secondary);
          }
        }
      }
    }
  }
  .about-image-gallery {
    padding: 0;
    .main-img-2 {
      text-align: right;
      margin-top: -240px;
      @media #{$large-mobile} {
        display: none;
      }
    }
    .shape-group {
      .shape-1 {
        top: 130px;
        right: 35px;
        left: auto;
      }
      .shape-2 {
        bottom: 240px;
        right: auto;
        left: -15px;
        z-index: 1;
        animation: rotateIt 10s linear infinite;
      }
      .shape-3 {
        bottom: 110px;
        left: 20px;
      }
    }
  }
}

.about-style-7 {
  position: relative;
  .about-image-gallery {
    position: relative;
    padding: 80px 0 0 70px;
    @media #{$sm-layout} {
      padding: 0;
    }
    .main-img-2 {
      position: absolute;
      right: 0;
      top: 15px;
      @media #{$sm-layout} {
        display: none;
      }
    }
    .shape-group {
      @media #{$sm-layout} {
        display: none;
      }
      li {
        position: absolute;
        z-index: -1;
        img {
          position: initial;
        }
        &.shape-1 {
          top: 0;
          left: -55px;
        }
        &.shape-2 {
          top: 0;
          left: 0;
          bottom: inherit;
          right: inherit;
        }
        &.shape-3 {
          bottom: -60px;
          right: -10px;
        }
        &.shape-4 {
          bottom: -80px;
          left: 10px;
          z-index: 1;
        }
      }
    }
  }
  .about-content {
    padding-right: 80px;
    padding-left: 0;
    @media #{$smlg-device} {
      padding-right: 30px;
    }
    @media only screen and (max-width: 991px) {
      padding-right: 0;
    }
    .section-title {
      p {
        margin-bottom: 28px;
        margin-top: 30px;
      }
    }
  }
  > .shape-group {
    > li {
      position: absolute;
      z-index: -1;
      top: 75px;
      left: 125px;
      height: 100px;
      width: 100px;
      border: 16px solid #f8b81f;
      border-radius: 50%;
      @media #{$lg-layout-1} {
        left: 20px;
      }
      @media #{$custom-laptop-device} {
        left: -20px;
        top: 45px;
      }
    }
  }
}

.about-style-8 {
  position: relative;
  &:before {
    content: '';
    height: 1030px;
    width: 100%;
    background: linear-gradient(-90deg, #f5f1eb 0%, rgba(245, 241, 235, 0.3) 100%);
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 90%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }
  .about-content {
    padding-right: 40px;
    padding-left: 0;
    @media #{$sm-layout} {
      padding-right: 0;
    }
    .section-title {
      p {
        margin-top: 30px;
      }
    }
    .about-mission {
      display: flex;
      align-items: center;
      margin-top: 45px;
      @media #{$large-mobile} {
        display: block;
      }
      .single-item {
        padding-right: 50px;
        flex: 1;
        @media #{$large-mobile} {
          padding-right: 0;
          margin-bottom: 30px;
        }
        &:last-child {
          padding-right: 0;
        }
        .icon {
          font-size: 50px;
          line-height: 1;
          margin-bottom: 25px;
          &.color-extra02 {
            color: var(--color-extra02);
          }
          &.color-extra06 {
            color: var(--color-extra06);
          }
        }
        .title {
          margin-bottom: 10px;
        }
      }
    }
  }
  .about-image-gallery {
    padding: 0;
    .thumbnail {
      img {
        width: 100%;
      }
    }
    .thumbnail-1 {
      text-align: right;
      margin-top: 40px;
      img {
        border-radius: 70px 10px 10px 10px;
      }
    }
    .thumbnail-2 {
      img {
        border-radius: 10px;
      }
    }
    .thumbnail-3 {
      text-align: right;
      img {
        border-radius: 10px 10px 90px 10px;
      }
    }
    .thumbnail-4 {
      img {
        border-radius: 10px;
      }
    }
    .shape-group {
      li {
        img {
          position: initial;
        }
        &.shape-1 {
          top: 0;
          left: -90px;
        }
        &.shape-2 {
          top: -20px;
          left: 25px;
          bottom: inherit;
          right: inherit;
        }
        &.shape-3 {
          bottom: 20px;
          right: -70px;
          @media only screen and (max-width: 991px) {
            display: none;
          }
        }
        &.shape-4 {
          bottom: -75px;
          right: -165px;
          span {
            height: 400px;
            width: 400px;
            border: 1px solid #e8e6e3;
            border-radius: 50%;
            display: block;
          }
        }
      }
    }
  }
  &:after {
    content: '';
    height: 600px;
    width: 600px;
    border: 1px solid #e8e6e3;
    border-radius: 50%;
    position: absolute;
    top: -175px;
    left: -70px;
    z-index: -1;
    @media #{$lg-layout-1} {
      left: -195px;
    }
    @media #{$custom-laptop-device} {
      left: -250px;
    }
  }
}
.about-style-9 {
  .about-image-gallery {
    padding: 15px 0 60px 0;
    margin-bottom: 34px;

    .author-box {
      position: absolute;
      right: 38px;
      bottom: -36px;
      @media #{$sm-layout} {
        right: 0;
      }
      @media (max-width: 450px) {
        display: none;
      }
    }
    .shape-group {
      li {
        &.shape-1 {
          left: -126px;
          top: -19px;
        }
        &.shape-2 {
          left: -63px;
          top: -47px;
        }
        &.shape-3 {
          right: -5px;
          bottom: 211px;
          z-index: 1;
          span {
            display: block;
            height: 101px;
            width: 101px;
            border: 15px solid var(--color-tertiary);
            border-radius: 50%;
          }
        }
      }
    }
  }
  .about-content {
    padding-left: 45px;
    @media #{$md-layout-1} {
      padding-left: 0;
      padding-top: 50px;
    }
    @media (max-width: 450px) {
      padding-top: 0;
      margin-top: -60px;
    }
    .edu-btn {
      margin-top: 25px;
    }
    .features-list {
      li {
        font-weight: var(--p-bold);
      }
    }
  }
  .shape-group {
    li {
      &.shape-4 {
        right: -150px;
        bottom: -60px;
      }
    }
  }
}
.language-about {
  .about-image-gallery {
    padding: 90px 0 20px;
    @media #{$md-layout-1} {
      text-align: center;
    }
    @media #{$small-mobile} {
      padding: 90px 0 0 0;
    }
    .main-img-1 {
      border-radius: 200px;
    }
    .main-img-wrapper {
      .main-img-inner {
        position: relative;
        right: 34px;
        bottom: 50px;
        @media #{$md-layout-1} {
          right: -105px;
        }
        @media #{$sm-layout} {
          right: -40px;
        }
        @media #{$large-mobile} {
          display: none;
        }
        .main-img-2 {
          border-radius: 120px;
          box-shadow: 0px 30px 90px 0px rgba(14, 4, 29, 0.15);
          z-index: 1;
        }
        &::after {
          content: '';
          position: absolute;
          width: 240px;
          height: 320px;
          background: var(--color-white);
          border-radius: 120px;
          bottom: -10px;
          right: 20px;
          @media #{$md-layout-1} {
            right: 134px;
          }
          @media #{$sm-layout} {
            right: 20px;
          }
        }
      }
    }
    .shape-group {
      li {
        &.shape-1 {
          bottom: 397px;
          right: 104px;
          top: inherit;
          left: inherit;
        }
        &.shape-2 {
          z-index: 12;
        }
      }
    }
  }
  .about-content {
    padding-left: 40px;
    padding-right: 0;
    padding-top: 40px;
    @media #{$sm-layout} {
      padding-left: 0;
    }
    .features-list {
      li {
        font-weight: var(--p-bold);
      }
    }
  }
  .about-btn {
    margin-top: 40px;
  }
  @media #{$md-layout-1} {
    padding: 0 0 100px;
  }
}
