//Faq Style
.faq-style-5 {
  .section-title {
    @media #{$md-layout-1} {
      text-align: center;
    }
  }
  .edu-faq-gallery {
    .thumbnail-1 {
      @media #{$md-layout-1} {
        text-align: center;
      }
    }
    .shape-group {
      li {
        &.shape-1 {
          top: 179px;
          left: 3px;
        }
        &.shape-2 {
          right: 147px;
          top: 61%;
        }
        &.shape-3 {
          bottom: 0;
          left: -66%;
        }
      }
    }
  }
  .faq-accordion {
    .accordion-item {
      .accordion-button {
        background: var(--gradient-primary);
        transition: background 0.3s ease;

        &.collapsed {
          background: transparent;
        }
      }
    }
  }
}
