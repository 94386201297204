.video-area-5 {
  position: relative;
  width: 100;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(../../../images/bg/testimonial-wave-bg.png);
    background-repeat: no-repeat;
    background-position: center top;
  }
  .video-gallery {
    .thumbnail {
      &::before {
        border-radius: 5px;
      }
      img {
        width: 100%;
        border-radius: 5px;
        box-shadow: 0px 30px 60px 0px rgba(15, 11, 44, 0.15);
      }

      .video-play-btn {
        background: var(--color-white);
        i {
          color: var(--color-secondary);
        }
        &::before {
          border: 1px solid rgba(238, 74, 98, 0.4);
        }
        &::after {
          border: 1px solid rgba(238, 74, 98, 0.4);
        }
      }
    }
    @media #{$md-layout-1} {
      .thumbnail-2 {
        margin-top: 50px;
      }
    }
  }
}
