.fresh-desk-widget {
  position: fixed;
  bottom: 20px;
  background-color: rgb(6, 181, 221);
  left: 30px;
  border-radius: 30px 8px 30px 30px;
  color: rgb(255, 255, 255);
  z-index: 100;
  cursor: pointer;
  padding: 8px;
  p {
    margin-bottom: 0;
    font-weight: 400;
    margin-left: 6px;
    margin-right: 8px;
    color: var(--color-text);
  }
}
