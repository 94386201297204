.banner-container {
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid #c1e0e8;
  background: #d1f2fa;
  .message {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #0f91ae;
  }
}
