/*------------------------------
    Popup Menu Styles  
--------------------------------*/
.popup-mobile-menu {
  z-index: 9999;
  content: '';
  width: 300px;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease-out;
  .inner {
    width: 305px;
    z-index: 999;
    position: absolute;
    background: #ffffff;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.06);
    height: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    opacity: 0;
    left: -150px;
    transition: all 0.5s ease-out;
    .header-top {
      display: flex;
      border-bottom: 1px solid var(--color-border);
      align-items: center;
      justify-content: space-between;
      padding: 15px 20px;
      .logo {
        a {
          img {
            max-height: 45px;
            &.logo-dark {
              display: none;
            }
            &.logo-light {
              display: inline-block;
            }
          }
        }
      }

      .close-menu {
        .close-button {
          background: var(--color-white);
          border: 0 none;
          color: var(--color-heading);
          width: 40px;
          height: 40px;
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.06);
          transition: var(--transition);
          &:hover {
            background-color: var(--color-primary);
            color: var(--color-white);
          }
        }
      }
    }
  }

  &.active {
    visibility: visible;
    opacity: 1;
    .inner {
      opacity: 1;
      left: 0;
      overflow-y: auto;
    }
  }

  .mainmenu {
    list-style: none;
    margin: 0;
    padding: 15px 20px;
    li {
      margin: 0;
      a {
        padding: 8px 0;
        display: block;
        font-size: 16px;
        font-weight: 500;
        &.active {
          color: var(--color-primary) !important;
        }
      }
      & + li {
        border-top: 1px solid var(--color-border);
      }
    }

    .has-droupdown {
      > a {
        position: relative;
        &::after {
          position: absolute;
          content: '\e942';
          font-family: 'icomoon', sans-serif !important;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          transition: 0.4s;
          font-size: 12px;
        }
        &.open {
          &::after {
            content: '\ea0b';
          }
        }
      }
      .submenu {
        padding: 0;
        max-width: 100%;
        list-style: none;
        padding-left: 14px;
        display: none;
        li {
          a {
            font-size: 15px;
            &.active {
              color: var(--color-primary) !important;
            }
          }
        }
      }
    }
  }
}

.mm-menu {
  padding: 15px 20px;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      display: block;
      padding: 0;
      margin: 0;
      &:hover {
        > a,
        button {
          color: var(--color-primary);
          &::after {
            color: var(--color-body);
          }
        }
      }
      a,
      button {
        padding: 8px 0;
        display: block;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        font-size: 16px;
        color: var(--color-body);
        font-weight: 500;
        position: relative;
        width: 100%;
        text-align: left;
        border: none;
        background-color: transparent;
        transition: 0.3s;
      }
      &:not(:last-child) {
        margin-bottom: 5px;
      }
      & + li {
        border-top: 1px solid var(--color-border);
      }
    }

    li.has-droupdown {
      > a,
      button {
        &::after {
          position: absolute;
          content: '\ea78';
          font-family: 'remixicon', sans-serif !important;
          width: 30px;
          height: 30px;
          background-color: #f3f3f3;
          text-align: center;
          font-size: 22px;
          line-height: 30px;
          display: inline-block;
          right: 0;
          top: 9px;
          cursor: pointer;
          transition: all 0.3s ease-out;
          font-weight: 400;
        }
      }
      &.active {
        > a {
          &::after {
            content: '\ea4e';
          }
          color: var(--color-primary);
          &:after {
            -webkit-transform: rotate(-135deg) translateY(-50%);
            transform: rotate(-135deg) translateY(-50%);
          }
        }
        ul.sub-menu.active {
          li {
            visibility: visible;
            opacity: 1;
          }
        }
        button {
          &::after {
            content: '\ea4e';
          }
        }
      }
      ul.sub-menu {
        list-style: none;
        height: 0;
        margin: 0;
        padding: 0;
        &.active {
          height: 100%;
        }
        li {
          margin-left: 15px;
          opacity: 0;
          &:not(:last-child) {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}

.body-overlay {
  &.apply {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    transition: 0.3s;
  }
}

[data-theme='dark'] {
  .mm-menu {
    ul {
      li {
        a,
        button {
          color: var(--color-white);
          &:hover {
            color: var(--color-primary);
          }
        }
        & + li {
          border-color: var(--dark-color-border);
        }
        &.has-droupdown {
          > a,
          button {
            color: var(--color-white);
            &::after {
              background-color: var(--bg-color-2);
            }
            &:hover {
              color: var(--color-primary);
            }
          }
        }
        &:hover {
          button {
            &::after {
              color: var(--color-white);
            }
          }
        }
      }
    }
  }
}
