/*-------------------------
    Common
-------------------------*/

.color-fb {
  color: var(--color-facebook) !important;
}
.color-ig {
  color: var(--color-instagram) !important;
}
.color-twitter {
  color: var(--color-twitter) !important;
}
.color-yt {
  color: var(--color-youtube) !important;
}
.color-linkd {
  color: var(--color-linkedin) !important;
}
.color-primary {
  color: var(--color-primary);
}
.color-secondary {
  color: var(--color-secondary);
}

.color-black {
  color: black;
  font-family: var(--font-primary);
}

// Background Color

.bg-lighten01 {
  background-color: var(--color-lighten01);
}
.bg-lighten02 {
  background-color: var(--color-lighten02);
}
.bg-lighten03 {
  background-color: var(--color-lighten03);
}
.bg-lighten04 {
  background-color: var(--color-lighten04);
}

.full-opacity {
  cursor: pointer;
  opacity: 1;
}

.is-error-box {
  color: white;
  margin-bottom: 174px;
  position: relative;
  top: 87px;
  font-size: var(--font-size-18);
}

.main-wrapper {
  font-family: var(--font-secondary);
}
