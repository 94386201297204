/*-------------------
  Pagination Styles
---------------------*/
.edu-pagination {
  @extend %liststyle;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 70px;
  li {
    margin: 0 2px;
    a {
      width: 50px;
      height: 50px;
      line-height: 50px;
      display: block;
      border-radius: 50%;
      text-align: center;
      color: var(--color-heading);
      transition: var(--transition);
      font-size: 18px;
      font-weight: 600;
      position: relative;
      z-index: 1;
      &:before {
        content: '';
        height: 100%;
        width: 100%;
        background: var(--gradient-primary);
        border-radius: 50%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transition: var(--transition);
        z-index: -1;
        opacity: 0;
      }
      i {
        font-weight: 700;
        position: relative;
        top: 2px;
      }
    }
    &.active,
    &:hover {
      a {
        color: var(--color-white);
        &:before {
          opacity: 1;
        }
      }
    }
    &.more-next {
      a {
        position: relative;
        &:after {
          content: '. . .';
          font-size: 18px;
          font-weight: 700;
          position: absolute;
          top: 40%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
        }
      }
    }
  }
  &.top-space-30 {
    padding-top: 30px;
  }
}
