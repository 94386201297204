/*==============================
 *  Shortcode
=================================*/

/*--------------------------------------*/
.container-custom,
.container-custom-1520 {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
}

.container-custom {
  max-width: 1710px;
}

.container-custom-1520 {
  max-width: 1520px;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl,
.container-custom,
.container-custom-1520 {
  @media #{$sm-layout} {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1185px;
  }
}

/*===========================
    Background Image 
=============================*/

%liststyle {
  padding: 0;
  margin: 0;
  list-style: none;
}

%transition {
  transition: var(--transition);
}

%bgImagePosition {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.bgImagePosition {
  @extend %bgImagePosition;
}

.bg-image {
  @extend %bgImagePosition;
}

.bg-image_fixed {
  background-attachment: fixed !important;
}

.bg-image--1 {
  background-image: url(../../images/bg/bg-image-1.png);
}

.bg-image--2 {
  background-image: url(../../images/bg/bg-image-2.jpg);
}

.bg-image--9 {
  background-image: url(../../images/bg/bg-image-9.png);
}

.bg-image--14 {
  background-image: url(../../images/bg/bg-image-14.jpg);
}

.bg-image--17 {
  background-image: url(../../images/bg/bg-image-17.webp);
}

.bg-image--19 {
  background-image: url(../../images/bg/bg-image-19.webp);
}

.bg-image--21 {
  background-image: url(../../images/bg/bg-image-21.webp);
}

/*===================
Custom Row
======================*/
.row--0 {
  margin-left: -0px;
  margin-right: -0px;

  & > [class*='col'] {
    padding-left: 0px;
    padding-right: 0px;
  }
}

.row--5 {
  margin-left: -5px;
  margin-right: -5px;

  & > [class*='col'] {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.row--10 {
  margin-left: -10px;
  margin-right: -10px;

  & > [class*='col'] {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.row--15 {
  margin-left: -15px;
  margin-right: -15px;

  & > [class*='col'] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.row--20 {
  margin-left: -20px;
  margin-right: -20px;

  // Responsive
  @media #{$laptop-device} {
    margin-left: -15px;
    margin-right: -15px;
  }

  @media #{$lg-layout} {
    margin-left: -15px;
    margin-right: -15px;
  }

  @media #{$md-layout} {
    margin-left: -15px;
    margin-right: -15px;
  }

  @media #{$sm-layout} {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }

  & > [class*='col'],
  & > [class*='col-'] {
    padding-left: 20px;
    padding-right: 20px;

    // Responsive
    @media #{$laptop-device} {
      padding-left: 15px;
      padding-right: 15px;
    }

    @media #{$lg-layout} {
      padding-left: 15px;
      padding-right: 15px;
    }

    @media #{$md-layout} {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }

    @media #{$sm-layout} {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }
}

.row--25 {
  margin-left: -25px;
  margin-right: -25px;

  // Responsive
  @media #{$laptop-device} {
    margin-left: -15px;
    margin-right: -15px;
  }

  @media #{$lg-layout} {
    margin-left: -15px;
    margin-right: -15px;
  }

  @media #{$md-layout} {
    margin-left: -15px;
    margin-right: -15px;
  }

  @media #{$sm-layout} {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }

  & > [class*='col'],
  & > [class*='col-'] {
    padding-left: 25px;
    padding-right: 25px;

    // Responsive
    @media #{$laptop-device} {
      padding-left: 15px;
      padding-right: 15px;
    }

    @media #{$lg-layout} {
      padding-left: 15px;
      padding-right: 15px;
    }

    @media #{$md-layout} {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }

    @media #{$sm-layout} {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }
}

.row--30 {
  margin-left: -30px;
  margin-right: -30px;

  // Responsive
  @media #{$laptop-device} {
    margin-left: -15px;
    margin-right: -15px;
  }

  @media #{$lg-layout} {
    margin-left: -15px;
    margin-right: -15px;
  }

  @media #{$md-layout} {
    margin-left: -15px;
    margin-right: -15px;
  }

  @media #{$sm-layout} {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }

  & > [class*='col'],
  & > [class*='col-'] {
    padding-left: 30px;
    padding-right: 30px;

    // Responsive
    @media #{$laptop-device} {
      padding-left: 15px;
      padding-right: 15px;
    }

    @media #{$lg-layout} {
      padding-left: 15px;
      padding-right: 15px;
    }

    @media #{$md-layout} {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }

    @media #{$sm-layout} {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }
}

.row--35 {
  margin-left: -35px;
  margin-right: -35px;

  // Responsive
  @media #{$laptop-device} {
    margin-left: -15px;
    margin-right: -15px;
  }

  @media #{$lg-layout} {
    margin-left: -15px;
    margin-right: -15px;
  }

  @media #{$md-layout} {
    margin-left: -15px;
    margin-right: -15px;
  }

  @media #{$sm-layout} {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }

  & > [class*='col'],
  & > [class*='col-'] {
    padding-left: 35px;
    padding-right: 35px;

    // Responsive
    @media #{$laptop-device} {
      padding-left: 15px;
      padding-right: 15px;
    }

    @media #{$lg-layout} {
      padding-left: 15px;
      padding-right: 15px;
    }

    @media #{$md-layout} {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }

    @media #{$sm-layout} {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }
}

.row--40 {
  margin-left: -40px;
  margin-right: -40px;

  // Responsive
  @media #{$laptop-device} {
    margin-left: -15px;
    margin-right: -15px;
  }

  @media #{$lg-layout} {
    margin-left: -15px;
    margin-right: -15px;
  }

  @media #{$md-layout} {
    margin-left: -15px;
    margin-right: -15px;
  }

  @media #{$sm-layout} {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }

  & > [class*='col'],
  & > [class*='col-'] {
    padding-left: 40px;
    padding-right: 40px;

    // Responsive
    @media #{$laptop-device} {
      padding-left: 15px;
      padding-right: 15px;
    }

    @media #{$lg-layout} {
      padding-left: 15px;
      padding-right: 15px;
    }

    @media #{$md-layout} {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }

    @media #{$sm-layout} {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }
}

.row--45 {
  margin-left: -45px;
  margin-right: -45px;

  // Responsive
  @media #{$laptop-device} {
    margin-left: -15px;
    margin-right: -15px;
  }

  @media #{$lg-layout} {
    margin-left: -15px;
    margin-right: -15px;
  }

  @media #{$md-layout} {
    margin-left: -15px;
    margin-right: -15px;
  }

  @media #{$sm-layout} {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }

  & > [class*='col'],
  & > [class*='col-'] {
    padding-left: 45px;
    padding-right: 45px;

    // Responsive
    @media #{$laptop-device} {
      padding-left: 15px;
      padding-right: 15px;
    }

    @media #{$lg-layout} {
      padding-left: 15px;
      padding-right: 15px;
    }

    @media #{$md-layout} {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }

    @media #{$sm-layout} {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }
}

.row--50 {
  margin-left: -50px;
  margin-right: -50px;

  // Responsive
  @media #{$laptop-device} {
    margin-left: -15px;
    margin-right: -15px;
  }

  @media #{$lg-layout} {
    margin-left: -15px;
    margin-right: -15px;
  }

  @media #{$md-layout} {
    margin-left: -15px;
    margin-right: -15px;
  }

  @media #{$sm-layout} {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }

  & > [class*='col'],
  & > [class*='col-'] {
    padding-left: 50px;
    padding-right: 50px;

    // Responsive
    @media #{$laptop-device} {
      padding-left: 15px;
      padding-right: 15px;
    }

    @media #{$lg-layout} {
      padding-left: 15px;
      padding-right: 15px;
    }

    @media #{$md-layout} {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }

    @media #{$sm-layout} {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }
}

.row--60 {
  margin-left: -60px;
  margin-right: -60px;

  // Responsive
  @media #{$laptop-device} {
    margin-left: -15px;
    margin-right: -15px;
  }

  @media #{$lg-layout} {
    margin-left: -15px;
    margin-right: -15px;
  }

  @media #{$md-layout} {
    margin-left: -15px;
    margin-right: -15px;
  }

  @media #{$sm-layout} {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }

  & > [class*='col'],
  & > [class*='col-'] {
    padding-left: 60px;
    padding-right: 60px;

    // Responsive
    @media #{$laptop-device} {
      padding-left: 15px;
      padding-right: 15px;
    }

    @media #{$lg-layout} {
      padding-left: 15px;
      padding-right: 15px;
    }

    @media #{$md-layout} {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }

    @media #{$sm-layout} {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }
}

// preloader
#edublink-preloader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99999999999999;
  overflow: hidden;
  background-image: linear-gradient(20deg, #31b978 0%, #1ab69d 100%);
  transition: all 1.5s ease-out;
}

#edublink-preloader.loaded .preloader-close-btn-wraper {
  bottom: 200%;
}

.preloader-close-btn-wraper {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 30px;
  transition: all 1.5s ease-out;
}

.preloader-close-btn-wraper .preloader-close-btn {
  border-radius: 50px;
  font-size: 13px;
  padding: 4px 23px;
  background: var(--color-black);
  color: var(--color-white);
  cursor: pointer;
  height: 45px;
  line-height: 35px;
  border: 0;
  text-transform: uppercase;
  font-weight: 600;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.preloader-spin-1 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #ffffff;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: edublink-preloader-bounce-animation 2s infinite ease-in-out;
}

.preloader-spin-2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #ffffff;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: edublink-preloader-bounce-animation 2s infinite ease-in-out;
  animation-delay: -1s;
}

@-webkit-keyframes edublink-preloader-bounce-animation {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

@keyframes edublink-preloader-bounce-animation {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

.shape-dark {
  visibility: hidden;
}
.shape-light {
  visibility: visible;
}

[data-theme='dark'] {
  .shape-light {
    visibility: hidden;
  }
  .shape-dark {
    visibility: visible;
  }
}

.d-none-shape-dark {
  display: none;
}
