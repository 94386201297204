/*-------------------
  Gallery Styles
---------------------*/
.edu-gallery-area {
  .gallery-grid-wrap {
    margin: -15px;
  }
}

.edu-gallery-grid {
  position: relative;
  display: block;
  padding: 15px;
  width: 33.33%;
  @media #{$sm-layout} {
    width: 50%;
  }
  @media #{$small-mobile} {
    width: 100%;
  }
  .thumbnail {
    position: relative;
    img {
      border-radius: 5px;
      width: 100%;
    }
    &::before {
      background: linear-gradient(-145deg, #31b978 0%, #1ab69d 100%);
      border-radius: 5px;
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: 0.3s;
    }
  }
  .zoom-icon {
    opacity: 0;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.3s;
    height: 70px;
    width: 70px;
    line-height: 70px;
    background-color: var(--color-white);
    border-radius: 50%;
    text-align: center;
    font-size: 20px;
    color: var(--color-primary);
    cursor: pointer;
    @media #{$sm-layout} {
      width: 50px;
      height: 50px;
      line-height: 52px;
      font-size: 16px;
    }
  }
  &:hover {
    .thumbnail {
      &:before {
        opacity: 0.9;
      }
    }
    .zoom-icon {
      top: 50%;
      opacity: 1;
    }
  }
}
