/*-------------------
 * Rainbow kit (wallet connect lib) overrides
---------------------*/

[data-rk] .ju367v9i {
  background: var(--color-primary) !important;
  border-radius: 5px !important;
  font-weight: 400 !important;
  height: 50px !important;
  text-align: center !important;
  white-space: nowrap !important;
  width: fit-content !important;
  @media (max-width: 767px) {
    width: 100% !important;
  }
}

[data-rk] ._1ckjpok6 {
  @media (max-width: 767px) {
    top: 0px !important;
  }
}
