a,
button {
  &.edu-btn {
    margin-top: 20px;
    text-align: center;
    border-radius: 5px;
    display: inline-block;
    height: 60px;
    line-height: 62px;
    color: var(--edu-btn-color);
    background: var(--color-primary);
    padding: 0 30px;
    font-size: 15px;
    font-weight: 500;
    transition: 0.4s;
    font-family: var(--font-secondary);
    border: 0 none;
    overflow: hidden;
    position: relative;
    z-index: 1;
    cursor: pointer;
    @media #{$sm-layout} {
      padding: 0 20px;
      font-size: 14px;
      height: 50px;
      line-height: 52px;
    }
    i {
      padding-left: 6px;
      position: relative;
      font-size: 11px;
      @media #{$sm-layout} {
        font-size: 9px;
      }
    }

    &:hover {
      &:after {
        left: 0;
        width: 100%;
      }
    }

    &.btn-large {
      padding: 0 40px;
      i {
        top: 0;
        padding-left: 10px;
      }
    }
    &.btn-csv-download {
      margin-top: 0px;
      max-width: 130px;
      width: 100%;
      @media #{$sm-layout} {
        max-width: 100%;
        margin-top: 8px;
      }
    }

    &.btn-fixed-width {
      width: 130px;
      margin-top: 0;
    }

    &.btn-update {
      margin-top: 0px;
      margin-left: 16px;
      height: 40px;
      min-width: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--color-primary);
      color: white !important;
      padding: 0 25px;
      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    &.btn-medium {
      height: 50px;
      line-height: 51px;
      padding: 0 25px;
      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    &.btn-small {
      height: 40px;
      line-height: 40px;
      padding: 0 20px;
      font-size: 14px;
    }

    &.left-icon {
      i {
        padding-left: 0;
        padding-right: 10px;
      }
    }

    &.btn-curved {
      border-radius: 30px 5px;
      &:hover {
        border-radius: 5px 30px;
      }
    }
    &.curved-medium {
      border-radius: 20px 5px;
      padding: 0 40px;
      &:hover {
        border-radius: 5px 25px;
      }
    }

    &.btn-secondary {
      background: var(--color-secondary);
      color: var(--color-white);
      &:hover {
        color: var(--color-white);
        background: var(--color-primary);
      }
    }
    &.btn-secondary1 {
      background: var(--color-secondary);
      color: var(--color-white);
      &:hover {
        color: var(--color-heading);
        background: var(--color-white);
      }
    }
    &.btn-border {
      background-color: transparent;
      border: 1px solid var(--color-border);
      color: var(--color-heading);
      padding: 0 25px;
      &:after {
        display: none;
      }
      &:hover {
        background-color: var(--color-primary);
        border-color: var(--color-primary);
        color: var(--color-white);
      }
    }

    &.active {
      --border-angle: 0turn;
      --main-bg: conic-gradient(from var(--border-angle), #213, #112 5%, #112 60%, #213 95%);
      border: solid 1px transparent;
      --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #1ab69d, #1ab69d 99%, transparent);

      background: var(--main-bg) padding-box, var(--gradient-border) border-box, var(--main-bg) border-box;

      background-position: center center;
      animation: bg-spin 3s linear infinite;

      &:hover {
        animation-play-state: paused;
      }
    }
  }
}

@keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}

@property --border-angle {
  syntax: '<angle>';
  inherits: true;
  initial-value: 0turn;
}
