.hero-banner {
  &.hero-style-9 {
    position: relative;
    overflow: hidden;
    .slider {
      width: 100%;
      height: 95vh;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      @media #{$md-layout-1} {
        height: auto;
      }
      .container {
        position: relative;
        padding-top: 200px;
        display: flex;
        flex-wrap: wrap;
        @media #{$custom-lg-device-eight} {
          padding-top: 150px;
        }
        @media #{$smlg-device} {
          padding-top: 100px;
        }
        .shape-group {
          li {
            &.shape-1 {
              left: -460px;
              top: -308px;
              z-index: -1;
              span {
                display: block;
                width: 474px;
                height: 474px;
                border: 1px solid var(--color-border);
                border-radius: 50%;
              }
            }
            &.shape-2 {
              left: -257px;
              top: 70px;
            }
          }
        }
      }
      .health-slider-content {
        width: calc(61% - 120px);
        position: relative;
        overflow: hidden;
        margin: 0;
        margin-right: 40px;
        @media #{$md-layout-1} {
          width: 100%;
          margin-right: 0;
          margin-bottom: 30px;
        }
        br {
          @media #{$small-mobile} {
            display: none;
          }
        }
        .swiper-slide {
          padding: 0;
          .inner {
            width: 100%;
            display: block;
            @media #{$md-layout-1} {
              text-align: center;
            }
            .pre-title {
              font-weight: var(--p-bold);
              text-transform: uppercase;
              letter-spacing: 2.5px;
              margin-left: 5px;
            }
            .title {
              padding-top: 15px;
            }
            p {
              font-size: 18px;
              color: var(--color-heading);
            }
          }
        }
      }
      .health-slider-main {
        width: calc(44vw + 80px);
        height: calc(80vh - 150px);
        overflow: hidden;
        margin: 0;
        position: absolute;
        left: calc(60% - 80px);
        top: 100px;
        @media #{$md-layout-1} {
          width: 100%;
          position: static;
        }
        .swiper-slide {
          padding: 0;
        }
        .slide-image {
          width: 100%;
          height: 100%;
          display: inline-block;
          background-position: center !important;
          background-size: cover !important;
        }
      }
    }
    .hero-slider-bg-controls {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: 10;
      @media (max-width: 1400px) {
        display: none;
      }

      .swiper-slide-controls {
        display: inline-block;
        width: 60px;
        height: 60px;
        line-height: 60px;
        transition: all 0.4s ease-out;
        background-color: var(--color-white);
        color: var(--color-heading);
        i {
          font-size: 20px;
          line-height: 58px;
        }
        cursor: pointer;

        &:hover {
          transition: all 0.4s ease-out;
          background-color: var(--color-primary);
          color: var(--color-white);
        }
      }
      .slide-prev,
      .slide-next {
        position: relative;
        width: 60px !important;
        margin-left: auto;
        margin-right: 6px;
        text-align: center;
        border-radius: 50%;
        box-shadow: var(--shadow-darker3);
      }
      .slide-next {
        right: 0px;
        top: -69px;
      }
      .slide-prev {
        right: -70px;
        top: 0;
      }
    }
    .shape-group {
      li {
        &.shape-3 {
          top: 35px;
          left: 39%;
        }
        &.shape-4 {
          bottom: 25%;
          left: 100px;
          @media #{$custom-lg-device-six} {
            left: 2px;
          }
          @media (max-width: 1400px) {
            display: none;
          }
        }
        &.shape-5 {
          right: 38.5%;
          bottom: 12.6%;
          z-index: 1;
        }
        &.shape-6 {
          right: 42.5%;
          bottom: 14.5%;
        }
      }
    }
  }
}
