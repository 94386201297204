.recharts-wrapper {
  overflow: hidden;
}

.recharts-text {
  font-size: 11px;
}

.dark-mode .recharts-text {
  fill: #ededed;
}

.light-mode .recharts-text {
  fill: #838383;
}

.recharts-cartesian-grid-vertical line {
  stroke: #a3a3a3;
}

.recharts-legend-item-text {
  font-size: 15px;
}

.chart-grid {
  display: grid;
  gap: 20px;
  width: 100%;
}

.chart-grid.two-charts {
  grid-template-columns: repeat(2, 1fr);
}

.chart-grid:not(.two-charts) {
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
}

.chart-label {
  text-align: center;
  font-size: var(--font-size-14);
  color: var(--color-text);
  margin-top: 10px;
}

.chart-fluid {
  padding: 0px;
  width: 100%;
  height: 300px;
  position: relative;
  display: flex;
  justify-content: center;
}

.blur-element {
  filter: blur(8px);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-text);
  padding: 20px;
  text-align: center;
}

.chart-container {
  overflow-x: auto;
  position: relative;
  max-width: 1160px !important;
  margin: 0 auto;
}

.chart-container h6 {
  color: var(--color-text);
  font-family: var(--font-primary);
  margin-bottom: 0px;
  font-size: 16px;
  text-align: left;
}

.chart-container p {
  color: var(--color-text);
  margin-top: 15px;
  margin-bottom: 20px;
}

.custom-tooltip-recharts {
  background: white;
  border: 1px solid #d3d3d3;
  padding: 16px;
  margin: 0px;
}

.custom-tooltip-recharts p {
  margin-bottom: 4px;
}

.custom-tooltip-recharts .custom-p {
  margin-bottom: 0px;
  margin-left: 0px;
}

.list-container ul {
  padding-left: 0px;
}

.custom-legend {
  display: flex;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-radius: 50%;
}

.custom-legend-wrapper {
  display: flex;
  align-items: center;
  margin: 5px;
}

.recharts-legend-wrapper {
  white-space: break-spaces;
  left: 18px !important;
}

.custom-legend-1 {
  list-style: none;
  padding: 0;
  margin: 0;
}

.legend-item-1 {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.legend-color-1 {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 8px;
}
