/*-------------------------
    Typography
-------------------------*/
html {
  overflow: hidden;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  font-size: 10px;
}

body {
  overflow: hidden;
  font-size: var(--font-size-b1);
  line-height: var(--line-height-b1);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-body);
  font-weight: 400;
  background-color: black;
  margin: 0;
  padding: 0;
  &.modal-open {
    padding: 0 !important;
  }
}

address,
p,
pre,
blockquote,
menu,
ol,
ul,
table,
hr {
  margin: 0;
  margin-bottom: 20px;
}

.display-one {
  font-size: 112px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0;
  margin-bottom: 20px;
  word-break: break-word;
  font-family: var(--font-secondary);
  color: var(--color-heading);
  a {
    color: inherit;
    transition: 0.5s;
  }
}

h1,
.h1 {
  font-size: var(--h1);
  line-height: var(--h1-lineHeight);
}

h2,
.h2 {
  font-size: var(--h2);
  line-height: var(--h2-lineHeight);
}

h3,
.h3 {
  font-size: var(--h3);
  line-height: var(--h3-lineHeight);
}

h4,
.h4 {
  font-family: var(--font-primary);
  font-size: var(--h4);
  line-height: var(--h4-lineHeight);
}

h5,
.h5 {
  font-size: var(--h5);
  line-height: var(--h5-lineHeight);
}

h6,
.h6 {
  font-size: var(--h6);
  line-height: var(--h6-lineHeight);
}

a {
  color: var(--color-heading);
  transition: 0.3s;
}
@media #{$smlg-device} {
  h1,
  .h1 {
    font-size: 44px;
  }
}
@media #{$md-layout} {
  h1,
  .h1 {
    font-size: 38px;
  }
  h2,
  .h2 {
    font-size: 30px;
  }

  h3,
  .h3 {
    font-size: 24px;
  }

  h4,
  .h4 {
    font-size: 18px;
  }
}

@media #{$sm-layout} {
  h1,
  .h1 {
    font-size: 34px;
  }

  h2,
  .h2 {
    font-size: 26px;
  }

  h3,
  .h3 {
    font-size: 22px;
  }
  h4,
  .h4 {
    font-size: 18px;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  font-weight: var(--p-bold);
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: var(--p-semi-bold);
}

p {
  font-size: var(--font-size-b1);
  line-height: var(--line-height-b1);
  font-weight: 400;
  color: var(--color-text);
  margin: 0 0 30px;

  @media #{$sm-layout} {
    margin: 0 0 20px;
  }

  &.b1 {
    font-size: var(--font-size-b1);
    line-height: var(--line-height-b1);
  }

  &.b2 {
    font-size: var(--font-size-b2);
    line-height: var(--line-height-b2);
  }
}

.b1 {
  font-size: var(--font-size-b1);
  line-height: var(--line-height-b1);
}

.b2 {
  font-size: var(--font-size-b2);
  line-height: var(--line-height-b2);
}

.table,
table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 20px 0 0 0px;
  width: 100%;
  --bs-table-bg: var(--color-bg) !important;
}

table a,
table a:link,
table a:visited {
  text-decoration: none;
}

cite,
.wp-block-pullquote cite,
.wp-block-pullquote.is-style-solid-color blockquote cite,
.wp-block-quote cite {
  color: var(--color-heading);
}

var {
  font-family: 'Courier 10 Pitch', Courier, monospace;
}

/*---------------------------
    List Style 
---------------------------*/
ul,
ol {
  padding-left: 18px;
}

ul {
  list-style: square;
  margin-bottom: 0px;
  padding-left: 32px;

  &.liststyle {
    &.bullet {
      li {
        font-size: var(--font-size-b1);
        line-height: var(--line-height-b1);
        color: var(--color-body);
        position: relative;
        padding-left: 30px;

        @media #{$sm-layout} {
          padding-left: 19px;
        }

        &::before {
          position: absolute;
          content: '';
          width: 6px;
          height: 6px;
          border-radius: 100%;
          background: var(--color-body);
          left: 0;
          top: 10px;
        }

        & + li {
          margin-top: 8px;
        }
      }
    }
  }

  li {
    list-style-type: none;
    font-size: var(--font-size-b1);
    line-height: var(--line-height-b1);
    margin-top: 10px;
    margin-bottom: 10px;
    color: var(--color-text);
    input {
      margin-right: 12px;
    }
    a {
      text-decoration: none;
      color: var(--color-body);
      transition: var(--transition);
      &:hover {
        color: var(--color-primary);
      }
    }
  }
  ul {
    margin-bottom: 0;
  }
}

ol {
  margin-bottom: 30px;
  li {
    font-size: var(--font-size-b1);
    line-height: var(--line-height-b1);
    color: var(--color-body);
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 400;
    a {
      color: var(--heading-color);
      transition: var(--transition);
      text-decoration: none;
      &:hover {
        color: var(--color-primary);
      }
    }
  }
  ul {
    padding-left: 30px;
  }
}

hr {
  display: block;
  height: 0;
  margin: 15px 0;
  border-top: 1px solid var(--color-border);
  background: transparent;
}
kbd {
  background: var(--color-primary);
}

.custom-tooltip {
  position: relative;
  margin-left: 6px;
}

.custom-tooltip .info-icon {
  position: relative;
  z-index: 1;
}

.custom-tooltip svg {
  margin-bottom: 2px;
}

.tooltip-content {
  position: absolute;
  z-index: 9999;
  background-color: var(--color-dashboard-bg);
  border: 1px solid var(--color-primary);
  border-radius: 4px;
  padding: 8px;
  font-size: 12px;
  color: var(--color-text);
  min-width: 250px;
  max-width: 500px;
  word-wrap: break-word;
  white-space: normal;
}

.tooltip-content-inner {
  font-weight: 500 !important;
}

.tooltip-content:hover .tooltip-content-inner {
  display: block; /* Display the tooltip on hover */
}

.no-wrap {
  white-space: nowrap;
}

.transaction-value {
  display: flex;
  justify-content: center;
  @media #{$sm-layout} {
    justify-content: end;
  }
}

.table-responsive {
  position: relative;
  overflow: visible;
}

.income-report-no-data {
  padding-top: 90px;
}

.font-size-12 {
  font-size: 12px;
}

.custom-h6 {
  margin-bottom: 10px;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 600;
  color: white;
  text-align: left;
}

.csv-export-ul {
  display: flex;
  justify-content: center;
  padding-left: 15px;
  padding-right: 15px;
  flex-direction: column;
  width: 50%;

  @media (max-width: 1500px) {
    width: 70%;
  }

  @media #{$sm-layout} {
    width: 100%;
  }

  @media (max-width: 1024px) {
    width: 90vw;
  }
}

.p-subtitle {
  margin-top: 15px !important;
  margin-bottom: 20px !important;
  color: var(--color-text);
}

.h6-title {
  color: white;
  font-family: var(--font-primary);
  margin-bottom: 0px !important;
  font-size: 16px !important;
  text-align: left;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-13 {
  font-size: 13px;
}
