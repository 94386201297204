$spacing: 1rem;

.demo {
  height: auto;
}

.divider {
  margin: #{$spacing * 2} 0;
}

.field {
  margin: #{$spacing * 3} 5px;
}

.root {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  min-width: 100%;
  min-height: 70px;
}

.form-select {
  font-size: 16px !important;
}

.select-box-container {
  margin-top: 16px;
  width: 100% !important;
}
