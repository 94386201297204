.about-counterup {
  overflow: hidden;
  position: relative;
  .about-style-3 {
    padding: 90px 0;
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: url(../../../images/bg/bg-image-38.webp);
    background-repeat: no-repeat;
    z-index: -1;
  }
  .about-course {
    padding-top: 30px;
    .about-course-box {
      padding: 50px 30px;
      text-align: center;
      border-radius: 4px;
      box-shadow: var(--shadow-darker);
      background-color: var(--color-white);
      -webkit-transition: var(--transition);
      transition: var(--transition);
    }
    .about-course-style-2 {
      max-width: 480px;
      min-height: 160px;
      background-color: var(--color-white);
      box-shadow: var(--shadow-darker);
      border-radius: 5px;
      display: flex;
      align-items: center;
      padding: 30px 0;
      text-align: left;
      padding-left: 30px;
      margin-bottom: 30px;
      .icon-online {
        margin: 0;
        background-color: rgba(26, 182, 157, 0.1);
        padding: 0 21px;
        svg {
          filter: brightness(0) saturate(100%) invert(71%) sepia(32%) saturate(6176%) hue-rotate(126deg) brightness(92%)
            contrast(80%);
        }
      }
      .instructor {
        margin: 0;
        background-color: rgba(238, 74, 98, 0.1);
        padding: 0 27px;
        svg {
          filter: brightness(0) saturate(100%) invert(29%) sepia(88%) saturate(1186%) hue-rotate(321deg)
            brightness(111%) contrast(87%);
        }
      }
      .certificate {
        margin: 0;
        background-color: rgb(231, 247, 254);
        padding: 0 22px;
        svg {
          filter: brightness(0) saturate(100%) invert(72%) sepia(64%) saturate(2427%) hue-rotate(167deg) brightness(95%)
            contrast(105%);
        }
      }
      .content {
        padding-left: 20px;
        .title {
          margin-bottom: 0;
          font-size: var(--h5);
          font-weight: var(--p-semi-bold);
        }
        p {
          margin: 0;
          padding-right: 40px;
          color: var(--color-heading);
        }
      }
      &.remote-course {
        margin-left: 50px;
        @media #{$smlg-device} {
          margin-left: 0;
        }
      }
    }
    .icon {
      height: 80px;
      width: 80px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 32px;
      margin: 0 auto 28px;
      transition: var(--transition);
    }
  }
  .shape-group {
    li {
      &.shape-1 {
        left: -39%;
        top: -153px;
        span {
          display: block;
          width: 400px;
          height: 400px;
          border: 1px solid var(--color-border);
          border-radius: 50%;
        }
      }
      &.shape-2 {
        left: -257px;
        top: 96px;
      }
      &.shape-3 {
        right: -204px;
        bottom: -199px;
      }
    }
  }
  .remote-training-about {
    .about-content {
      padding-left: 30px;
      padding-right: 0;
      @media #{$lg-layout} {
        padding-left: 60px;
      }
      @media #{$md-layout-1} {
        padding-left: 0;
      }
    }
    .features-list {
      padding-bottom: 20px;
      li {
        font-weight: var(--p-bold);
      }
    }
  }
}
