/*-------------------
  Instagram Styles
---------------------*/
.instagram-grid {
  text-align: center;
  a {
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    display: block;
    img {
      border-radius: 5px;
      transition: var(--transition);
      width: 100%;
    }
    &:after {
      content: '';
      height: 100%;
      width: 100%;
      background-color: var(--color-black);
      border-radius: 5px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      visibility: hidden;
      opacity: 0;
      transition: var(--transition);
    }
    .user-info {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%) scale(1.5);
      z-index: 1;
      color: var(--color-white);
      visibility: hidden;
      opacity: 0;
      transition: var(--transition);

      .icon {
        display: block;
        font-size: 26px;
        // @media #{$smlg-device} {
        //     font-size: 20px;
        // }
      }
      .user-name {
        font-size: 18px;
        font-family: var(--font-secondary);
        font-weight: var(--p-semi-bold);
        // @media #{$smlg-device} {
        //     font-size: 15px;
        // }
      }
    }
  }
  &:hover {
    a {
      img {
        transform: scale(1.1);
      }
      &:after {
        visibility: visible;
        opacity: 0.5;
      }
      .user-info {
        transform: translateY(-50%) scale(1);
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
