.csv-export-button {
  border: 1px solid var(--color-primary);
  border-radius: 8px;
  margin-left: 24px;
  margin-bottom: 4px;
  background: transparent;
  transition: box-shadow 0.5s ease;
  max-height: 27px;

  &:hover {
    box-shadow: 0 0 8px var(--color-primary);
    cursor: pointer;
  }
}
