// Brand Style
.brand-area-6 {
  background-color: var(--cta-color-bg);
  padding: 30px 0;

  .brand-grid-wrap {
    .brand-grid {
      border-right: 1px solid #e8eced;
    }
  }
}
