/*-------------------------
    Theme Switcher Style
---------------------------*/

.theme-switcher {
  position: fixed;
  left: 0;
  top: 200px;
  z-index: 123;
  background: #333;
  border: 2px solid rgba(255, 255, 255, 0.07);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  padding: 6px 6px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;

  #moon {
    width: 25px;
    margin-bottom: 0;
    cursor: pointer;
  }
}
